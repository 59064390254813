import showNotification from '../xz_notify_lib';

$(document).on('click', '.js-build-slug', function updateTranslitClick() {
  const inputName = $(this).data('inputName');
  const title = $(this).closest('form').find(`[name*=${inputName}]`).val();

  $.ajax({
    type: 'POST',
    url: `/admin/slugs?title=${title}`,
    success: ({ slug }) => {
      $(this).closest('div').find('input').attr('value', slug)
        .val(slug);
    },
    error: (data) => {
      showNotification(data.responseJSON.errors, { type: 'error' });
    },
  });
});
